import React, { useState } from "react";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Formulärdata:", formData);
    setSubmitted(true);

    // Här kan du skicka data till en server, t.ex. via fetch/axios
    // fetch('/api/contact', { method: 'POST', body: JSON.stringify(formData) })
  };

  return (
    <div className="contact-form-container">
      <h2>Kontakta mig nedan</h2>
	<h2>070-523 28 40</h2>
	<h2>stefan@axstad.se</h2>
    </div>
  );
};

export default Contact;
