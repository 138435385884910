// src/pages/ImageGallery.js
import React, { useState } from "react";
import "./ImageGallery.css"; // Import a CSS file for styling

function importAll(r) {
    return r.keys().map(r);
  }

  const bygget = importAll(require.context("../resources/bygget", false, /\.(png|jpe?g|svg)$/));


  function ImageGallery() {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const handleImageClick = (src) => {
      if (selectedImage === src) {
        setSelectedImage(null); // Close the image if clicked again
      } else {
        setSelectedImage(src); // Open the clicked image
      }
    };
  
    return (
      <div className="gallery-container">
        <h1>Bygget</h1>
        <div className="gallery-grid">
          {bygget.map((src, index) => (
            <div key={index} className="gallery-item" onClick={() => handleImageClick(src)}>
              <img src={src} alt={`Gallery ${index + 1}`} />
            </div>
          ))}
        </div>
  
        {selectedImage && (
          <div className="overlay" onClick={() => setSelectedImage(null)}>
            <img src={selectedImage} alt="Selected" className="full-image open" />
          </div>
        )}
  
        {selectedImage && (
          <div className="overlay" onClick={() => setSelectedImage(null)}>
            <img src={selectedImage} alt="Selected" className="full-image open" />
          </div>
        )}

      </div>
    );
  }
  

export default ImageGallery;

