// src/pages/About.js
import React, { useEffect } from "react";
import "./About.css"; // Create a CSS file for styling
import axstadImage from "../resources/Potatis/Plockning1.jpg"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';


function About() {
  return (
    <div>
      <div className="content-area">
        <div className="about-me">
          <div className="about-me-text">
            <h1>Axstad</h1>
            <h3>Axstad ligger i Mjölby kommun i närheten av Högby. </h3>
            <MapComponent/>
          </div>
          <img src={axstadImage} alt="Potatis skörd" className="about-me-image" />
        </div>
      </div>
    </div>
  );
}

export function MapComponent() {
  useEffect(() => {
      // Fix default marker images
      const DefaultIcon = L.icon({
        iconUrl: markerIcon,
        shadowUrl: markerShadow,
        iconSize: [25, 41], // Default icon size
        iconAnchor: [12, 41], // Point where the icon's bottom is anchored
        popupAnchor: [1, -34], // Point where the popup opens relative to the iconAnchor
        shadowSize: [41, 41], // Default shadow size
    });
    L.Marker.prototype.options.icon = DefaultIcon;
    
      // Initialize the map after the component mounts
      const map = L.map('map').setView([58.3490, 15.0587], 13);

      // Add OpenStreetMap tiles
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors',
          maxZoom: 19,
      }).addTo(map);

      // Add a marker
      L.marker([58.3490, 15.0587]).addTo(map);

      return () => {
          // Cleanup: Remove the map instance when the component unmounts
          map.remove();
      };
  }, []);

  return (
      <div
          id="map"
          style={{
              width: '100%',
              height: '500px', // Height is required for Leaflet to render properly
          }}
      />
  );
}

export default About;
