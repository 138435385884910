// src/pages/Services.js
import React from "react";
import meImage from "../resources/resurser/baksida.jpg"
import potatisplockning from "../resources/Potatis/Plockning3.jpg"


function Services() {
  return (
    <div>
      <div className="content-area">
        <div className="about-me">
          <div className="about-me-text">
            <h1>Jag servar och reparerar maskiner </h1>
            <h3>Här är ett exmepel på detta!</h3>
          </div>
          <img src={meImage} alt="Stefan" className="about-me-image" />
        </div>
        <div className="about-me">
          <img src={potatisplockning} alt="Stefan" className="about-me-image" />
          <div className="about-me-text">
            <h1>Jag har odlat potatis länge, nu arrenderar jag ut marken. </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
