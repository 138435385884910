// src/App.js
import React, { useState } from "react";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import ImageGallery from "./pages/ImageGallery";
import logo from "./resources/axstad_logga.svg"; // Path to your logo image


function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="page-container">
      <div className="header">
        <nav>
          <div className="navbar-logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <button className="burger-menu" onClick={toggleMenu}>
            ☰
          </button>
          <div onClick={toggleMenu} className={`menu-blocker ${isOpen ? "open" : ""}`}>
            <ul className={`nav-links ${isOpen ? "open" : ""}`} >
              <li><Link onClick={toggleMenu} to="/">Hem</Link></li>
              <li><Link onClick={toggleMenu} to="/gallery">Bilder</Link></li>
              <li><Link onClick={toggleMenu} to="/about">Om Axstad</Link></li>
              <li><Link onClick={toggleMenu} to="/services">Tjänster</Link></li>
              <li><Link onClick={toggleMenu} to="/contact">Kontakt</Link></li>
            </ul>
          </div>
          
        </nav>
      </div>
      
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<ImageGallery />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
        <div className="footer-area">
          <h2>Kontakt</h2>
          <h4>Stefan Axby</h4>
          <h4>
            <a
              href={`https://maps.app.goo.gl/sgZoiV6pbePQsfJc8`}
              target="_blank"
              rel="noopener noreferrer">
              Axstad Mellangård 2,<br/>
              595 94 Mjölby
            </a>
          </h4>
          <h4><a href={`tel:${"+46705232840"}`}>{"070 523 28 40"}</a></h4>
          <h4><a href={`mailto:${"stefan@axstad.se"}`}>{"stefan@axstad.se"}</a></h4>
      </div>
    </div>
  );
}

export default App;
