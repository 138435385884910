// src/pages/Home.js
import React from "react";
import "./Home.css"; // Create a CSS file for styling
import meImage from "../resources/resurser/474483994_2031700134003331_5135611174821395107_n.jpg"
import logo from "../resources/resurser/sunset-over-tranquil-farmland.jpg"

function Home() {
  return (
    <div>
      <div className="content-area">
        <div className="about-me">
          <div className="about-me-text">
            <h1>Axstad Lantbruk & Service </h1>
            <h3>Axstad Mellangård är en familjegård som idag driver spanmålsodling och service av olika maskiner!</h3>	
          </div>
          <img src={meImage} alt="Stefan" className="about-me-image" />
        </div>
      </div>
    </div>
  );
}

export default Home;
